import React from 'react';
import './index.css';

const NotFoundPage = () => (
  <div className="background-image" style={{minHeight: '100vh'}}>
    <div className="container">
      <div className="h-100 d-flex flex-column justify-content-center align-items-center">
        <h3 className="not-found-title">Page Not Found!</h3>
        <h3 className="not-found-desc">You just hit a route that doesn&#39;t exist.</h3>
        <a className="not-found-button" href="/">
          Go to Homepage
        </a>
      </div>
    </div>
  </div>
);

export default NotFoundPage;
